import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import useAuth from "../../hooks/useAuth";
import MenuNotifications from "./sub-components/MenuNotificatons";
import { useOutsideClick } from "../../hooks/useOutsideClick";
// import { getProducts, setSearchQuery } from "../../store/slices/filters-slice";

const IconGroup = ({ iconWhiteClass, notifications, error }) => {
  const searchQuery = useSelector((state) => state.filters.searchQuery);
  const token = localStorage.getItem("token");
  const { logout } = useAuth();

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  // Outside click handlers for each dropdown
  const accountDropdownRef = useOutsideClick(() => {
    const dropdown =
      accountDropdownRef.current.querySelector(".account-dropdown");
    if (dropdown && dropdown.classList.contains("active")) {
      dropdown.classList.remove("active");
    }
  });

  const notificationDropdownRef = useOutsideClick(() => {
    const dropdown = notificationDropdownRef.current.querySelector(
      ".shopping-cart-content"
    );
    if (dropdown && dropdown.classList.contains("active")) {
      dropdown.classList.remove("active");
    }
  });

  const cartDropdownRef = useOutsideClick(() => {
    const dropdown = cartDropdownRef.current.querySelector(
      ".shopping-cart-content"
    );
    if (dropdown && dropdown.classList.contains("active")) {
      dropdown.classList.remove("active");
    }
  });

  const getUnreadNotifications = () => {
    let count = 0;
    if (notifications.getNotifications) {
      notifications.getNotifications.forEach((notification) => {
        if (notification.isUnread === true) {
          count++;
        }
      });
      return count;
    }
    return 0;
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const { compareItems } = useSelector((state) => state.compare);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);

  const Admindata = localStorage.getItem('accessToken') ? true : false;
  const accessToken = localStorage.getItem('accessToken')
  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      {/* Account Dropdown */}
      <div
        className="same-style account-setting d-none d-lg-block"
        ref={accountDropdownRef} // Attach the ref to the account dropdown
      >
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            {token ? (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/dashboard/my-account"}>
                    my account
                  </Link>
                </li>
                <li>
                  <Link to={Admindata ? `https://airomnishopvander.dev-bt.xyz/dashboard?accessToken=${accessToken}` : process.env.PUBLIC_URL + "/dashboard/BecomeSeller"}>
                    {Admindata ? "Dasboard" : "Become a seller"}
                  </Link>
                </li>
                <li
                  onClick={() => {
                    localStorage.clear()
                    logout();
                  }}
                >
                  <Link to={process.env.PUBLIC_URL + "/login"}>logout</Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login"}>Login</Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/register/user"}>
                    Register
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/register/seller"}>
                    Seller Register
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {/* Conditional Rendering when Token exists */}
      {
        token && (
          <>
            {/* Compare Dropdown */}
            <div className="same-style header-compare">
              <Link to={process.env.PUBLIC_URL + "/dashboard/compare"}>
                <i className="pe-7s-shuffle" />
                {compareItems && compareItems.length > 0 ? (
                  <span className="count-style">
                    {compareItems && compareItems.length}
                  </span>
                ) : (
                  ""
                )}
              </Link>
            </div>

            {/* Chat Dropdown */}
            <div className="same-style header-compare">
              <Link to={process.env.PUBLIC_URL + "/dashboard/chat"}>
                <i className="pe-7s-chat" />
              </Link>
            </div>

            {/* Notifications Dropdown */}
            <div className="same-style cart-wrap d" ref={notificationDropdownRef}>
              <button className="icon-cart" onClick={(e) => handleClick(e)}>
                <i className="pe-7s-bell" />
                {notifications?.getNotifications?.length > 0 ? (
                  ""
                ) : (
                  <span className="count-style">
                    {notifications?.getNotifications && getUnreadNotifications()}
                  </span>
                )}
              </button>
              {/* menu notifications */}
              <MenuNotifications
                notifications={notifications?.getNotifications || []}
                error={error}
              />
            </div>

            {/* Wishlist */}
            <div className="same-style header-wishlist">
              <Link to={process.env.PUBLIC_URL + "/dashboard/wishlist"}>
                <i className="pe-7s-like" />
                <span className="count-style">
                  {wishlistItems && wishlistItems.length
                    ? wishlistItems.length
                    : 0}
                </span>
              </Link>
            </div>
          </>
        )
      }

      {/* Cart Dropdown (desktop) */}
      <div
        className="same-style cart-wrap d-none d-lg-block"
        ref={cartDropdownRef}
      >
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart />
      </div>

      {/* Cart Link (mobile) */}
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>

      {/* Mobile menu */}
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div >
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;
